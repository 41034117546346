import { FC } from "react"
import { Layout, Menu, Space } from "antd"
import { Outlet, useLocation, NavLink, useNavigate } from "react-router-dom"
import { UserOutlined } from "@ant-design/icons"

import { isAuthenciacted, signOut } from "../../utils/apis"
import { MessageInstance } from "antd/es/message/interface"

const navItems = () => {
  const loginState = isAuthenciacted()
  return [
    loginState === null
      ? {
        label: "Account",
        key: "/account",
        icon: <UserOutlined />,
        children: [
          {
            label: "Sign In",
            key: "/signIn",
          },
          {
            label: "Sign Up",
            key: "/signUp",
          },
        ],
      }
      : {
        label: loginState.user.email,
        key: "/account",
        icon: <UserOutlined />,
        children: [
          {
            label: "My Settings",
            key: "/me",
          },
          {
            label: "My Progress",
            key: "/progress",
          },
          {
            label: "My Messages",
            key: "/messages"
          },
          {
            label: "My Usage",
            key: "/usage",
          },
          {
            label: "Sign Out",
            key: "/signOut",
          },
        ],
      },
    { label: "Past Year Papers", key: "/pastpapers" },
    {
      label: "Topical Questions", key: "/questions", children: [
        { label: "Past Exam Compilation", key: "/questions/0" },
        { label: "Qbank Add-on", key: "/questions/1" },
      ]
    },
    {
      label: "Exam Builder", key: "/builder", children: [
        { label: "Past Exam Compilation", key: "/builder/0" },
        { label: "Qbank Add-on", key: "/builder/1" },
      ]
    },
  ]
}

const Page: FC<{ messageApi: MessageInstance; contextHolder: React.ReactElement<any, string | React.JSXElementConstructor<any>> }> = ({ messageApi, contextHolder }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {contextHolder}
      <Layout.Header style={{ position: "fixed", zIndex: 99, width: "100vw" }}>
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[pathname]}
          items={navItems()}
          onSelect={(e) => {
            if (e.key === "/signOut") {
              signOut(() => {
                messageApi.success({ content: "Signed Out Successfully!", duration: 2 })
                navigate(0)
              })
            } else {
              navigate(e.key)
            }
          }}
        ></Menu>
      </Layout.Header>
      <Outlet />
      <Layout.Footer style={{ textAlign: "center" }}>
        <Space direction="vertical">
          MyQuestionBank @2022
          <NavLink to="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
            闽ICP备2022016009号-1
          </NavLink>
        </Space>
      </Layout.Footer>
    </Layout>
  )
}

export { Page }
