export enum QuestionBank {
  Topical = 0,
  Special = 1,
  Pastpaper = 2,
}

export interface Query {
  questionBank: QuestionBank
  examId?: string
  subjectId?: string
  properties: { [key: string]: string | number }
}
