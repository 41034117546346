import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { Button, Layout, Row, Space, Col, Table } from "antd"
import { DownOutlined, UpOutlined } from "@ant-design/icons"
import { useLoaderData, useNavigate } from "react-router-dom"

import { Selector } from "../../components/selector"

import { OrderBy, getPastpapers, firstSignInCheck, OrderType } from "../../utils/apis"
import { PageSize } from "../../utils/constants"

import "./index.scss"
import { MessageInstance } from "antd/es/message/interface"
import { QuestionBank, Query } from "../../types/query.d"
import { ColumnsType } from "antd/es/table"

const OrderByButton: FC<{
  field: string
  orderBy: OrderBy
  setOrderBy: Dispatch<SetStateAction<OrderBy>>
}> = ({ field, orderBy, setOrderBy }) => {
  const myField = `properties.${field}`

  const handleClick = () => {
    const nextOrderType = orderBy?.orderType === OrderType.Assending ? OrderType.Desending : OrderType.Assending
    const nextOrderBy: OrderBy = { field: myField, orderType: nextOrderType }
    setOrderBy(nextOrderBy)
  }

  return (
    <Button style={{ width: "90px" }} onClick={handleClick}>
      <Space size="small">
        {field[0].toUpperCase() + field.slice(1)}
        {
          orderBy.field === `properties.${field}` && (
            orderBy.orderType === OrderType.Assending ? (
              <UpOutlined />
            ) : (
              <DownOutlined />
            )
          )
        }
      </Space>
    </Button>
  )
}

const Pastpapers: FC<{ messageApi: MessageInstance }> = ({ messageApi }) => {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ])
  const [smallScreen, setSmallScreen] = useState<boolean>(false)

  useEffect(() => {
    setSmallScreen(windowSize[0] < 1000)
  }, [windowSize])

  // console.debug(myExams, mySubjects)
  const navigate = useNavigate()
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', handleWindowResize)
    firstSignInCheck(navigate)
      .then(({ subjects }) => {
        // console.log("questions", subjects);
      }
      )
      .catch((err) => console.error(err))
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [navigate])

  const { exams } = useLoaderData() as { exams: Exams }
  const [pastpapers, setPastpapers] = useState<Pastpaper[]>([])
  const [page, setPage] = useState<number>(1)
  const [examId, setExamId] = useState<string>()
  const [subject, setSubject] = useState<string>()
  const [label, setLabel] = useState<number>(0)
  const [orderBy, setOrderBy] = useState<OrderBy>({ field: "properties.year", orderType: OrderType.Desending })
  const [properties, setProperties] = useState<{ [k: string]: any }>({})
  const [total, setTotal] = useState<number>(0)

  const [loading, setLoading] = useState<boolean>(false)

  const handleQuery = (q: Query) => {
    // console.log(q)
    const { examId, subjectId, properties } = q
    // assign exam, subject and properties from
    if (examId !== "") {
      setExamId(examId)
    }
    if (subjectId !== "") {
      setSubject(subjectId)
    }
    setProperties(Object.fromEntries(Object.entries(properties).map(([n, v]) => [n, v])))

    setPage(1)
  }

  useEffect(() => {
    setLoading(true)

    getPastpapers({
      query: {
        label: label,
        subject: subject,
        properties: properties,
        orderBy: orderBy,
        page: page,
      }
    }).then(({ data, total }) => {
      // console.log(data, total);
      setPastpapers(data)
      setTotal(total)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      messageApi.error({ content: `Error: ${err}`, duration: 3 })
    })
  }, [label, examId, subject, properties, orderBy, page, messageApi])

  const columns: ColumnsType<Pastpaper> = [
    {
      title: 'Exam',
      dataIndex: 'exam',
      key: 'exam',
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: 'Year',
      dataIndex: ['properties', 'year'],
      key: 'properties.year',
      align: 'center',
      render: (year) => year || "--"
    },
    {
      title: 'Season',
      dataIndex: ['properties', 'season'],
      key: 'properties.season',
      align: 'center',
      render: (season) => season || "--"
    },
    {
      title: 'Paper',
      dataIndex: ['properties', 'paper'],
      key: 'properties.paper',
      align: 'center',
      render: (paper) => paper || "--"
    },
    {
      title: 'Time Zone',
      dataIndex: ['properties', 'timezone'],
      key: 'properties.timezone',
      align: 'center',
      render: (timezone) => timezone || "--"
    },
    {
      title: 'Question Paper',
      dataIndex: 'question',
      key: 'question',
      align: 'center',
      render: (file) => <Button disabled={!file} type="link" size="small" target="_blank" href={file ? file.url : ""}>view</Button>
    },
    {
      title: 'Mark Scheme',
      dataIndex: 'answer',
      key: 'answer',
      align: 'center',
      render: (file) => <Button disabled={!file} type="link" size="small" target="_blank" href={file ? file.url : ""}>view</Button>
    }
  ]

  return (
    <Layout.Content className="site-layout-background" style={{ paddingBottom: "30px" }}>
      <Space
        direction="vertical"
        style={{
          display: "flex",
          margin: "auto",
          marginTop: 80,
          width: smallScreen ? "90vw" : "min(21cm, 90vw)",
          // width: "21cm",
          // maxWidth: "max(60vw, 380px)",
        }}
      >
        <Selector questionBank={QuestionBank.Pastpaper} loading={loading} exams={exams} setQuery={handleQuery} />
        <Row justify="space-between" gutter={[0, 10]}>
          <Row justify="space-between" gutter={[10, 10]}>
            {
              ["year", "paper"].map(t =>
                <Col key={t}><OrderByButton field={t} orderBy={orderBy} setOrderBy={setOrderBy} /></Col>
              )
            }
          </Row>
        </Row>
        <Table
          columns={columns}
          dataSource={pastpapers}
          size="middle"
          loading={loading}
          pagination={{
            onChange: (current) => setPage(current),
            current: page,
            pageSize: PageSize * 4,
            showSizeChanger: false,
            total,
          }}
        />
      </Space>
    </Layout.Content>
  )
}

export { Pastpapers }
