import { Dispatch, FC, SetStateAction, useState } from "react";
import { InputNumber, Space, Typography } from "antd";

const { Text } = Typography;

const MoveQuestion: FC<{
  questions: Array<Question>;
  idx: number;
  setQuestions: Dispatch<SetStateAction<Array<Question>>>;
}> = ({ questions, idx, setQuestions }) => {
  const [index, setIndex] = useState<number>(idx);
  const handleChange = (i: number | null) => {
    if (!i) return;
    setIndex(i);
  };
  const reOrder = () => {
    let qs = [...questions];
    const current = qs.splice(idx - 1, 1)[0];
    qs.splice(index - 1, 0, current);
    setQuestions(qs);
  };
  return (
    <Space>
      <Text>Move to index</Text>
      <InputNumber size="small" min={1} max={questions.length} defaultValue={index} onChange={handleChange} />
      <a onClick={() => reOrder()}>confirm</a>
    </Space>
  );
};

export { MoveQuestion };
