import { FC, useEffect } from "react"
import { Button, Form, Input, Layout, Space, notification } from "antd"
import { useNavigate } from "react-router-dom"
import { MessageInstance } from "antd/es/message/interface"

import { signUp, isAuthenciacted } from "../../utils/apis"

const { Content } = Layout

const SignUp: FC<{ messageApi: MessageInstance }> = ({ messageApi }) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenciacted()) navigate("/")
  }, [])

  const openNotification = () => {
    const key = `${Date.now()}`
    notification.success({
      key: key,
      message: "Successfully submitted!",
      description:
        "Please click the link in the email we sent to you to verify your account if the email you entered has not been registered yet.",
      btn: (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            navigate("/")
            notification.destroy(key)
          }}
        >
          Back to Home page
        </Button>
      ),
      duration: 0, // never auto close
    })
  }

  const onFinish = (values: any) => {
    // console.log("Received values of form:", values);
    signUp(values)
      .then((res) => {
        // console.log(res);
        openNotification()
      })
      .catch((err) => openNotification())
  }

  return (
    <Content className="site-layout-background" style={{ paddingBottom: "30px" }}>
      <Space
        direction="horizontal"
        align="start"
        style={{
          columnGap: 15,
          display: "flex",
          margin: "auto",
          justifyContent: "center",
          maxWidth: "90vw",
        }}
      >
        <Space
          direction="vertical"
          style={{
            display: "flex",
            margin: "auto",
            marginTop: 80,
            width: "21cm",
            maxWidth: "max(60vw, 380px)",
          }}
        >
          <Form
            name="signup"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            style={{ maxWidth: 700 }}
            layout="horizontal"
            onFinish={onFinish}
            size={"large"}
          >
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Please input your email!" },
                { type: "email", message: "Please input a valid email!" },
                { max: 80, message: "The email length should be no more than 80 characters." },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: "Please input your password!" },
                { min: 8, message: "The password length should be no less than 8 characters." },
                { max: 32, message: "The password length should be no more than 20 characters." },
                {
                  pattern: /^(?=.*[A-Za-z])(?=.*\d).+$/,
                  message: "The password must contain both letters and numbers. Space is not allowed.",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                { required: true, message: "Please confirm your password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error("The two passwords that you entered do not match!"))
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button block type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Space>
    </Content>
  )
}

export { SignUp }
