import { useState, useEffect, FC } from "react";
import { Layout, Space, List, } from "antd";
import { MessageInstance } from "antd/es/message/interface";
import { useNavigate } from "react-router-dom";

import { getMessages, isAuthenciacted } from "../../utils/apis";

const { Content } = Layout;

interface Message {
  _id: string;
  title: string;
  content: string;
  createTime: string;
}

const Messages: FC<{ messageApi: MessageInstance }> = ({ messageApi }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);


  useEffect(() => {
    if (!isAuthenciacted()) navigate("/");
    setLoading(true);
    getMessages().then(({ data }) => {
      // console.log(data);
      setMessages(data);
      setLoading(false);
    }).catch((err) => {
      messageApi.error(`Error ${err}`);
      setLoading(false);
    })
  }, []);

  return (
    <Content className="site-layout-background" style={{ paddingBottom: "30px" }}>
      <Space
        direction="horizontal"
        align="start"
        style={{
          columnGap: 15,
          display: "flex",
          margin: "auto",
          justifyContent: "center",
          maxWidth: "90vw",
        }}
      >
        <Space
          direction="vertical"
          style={{
            display: "flex",
            margin: "auto",
            marginTop: 80,
            padding: "1em",
            width: "21cm",
            maxWidth: "max(60vw, 380px)",
          }}
        >
          <List bordered loading={loading}>
            {
              messages.map((message) => (
                <List.Item key={message._id}>
                  <List.Item.Meta
                    title={message.title + " - " + new Date(message.createTime).toLocaleString()}
                    description={message.content}
                  />
                </List.Item>))
            }
          </List>
        </Space>
      </Space>
    </Content>
  );
};

export { Messages };
