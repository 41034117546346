import cloudbase from "@cloudbase/js-sdk"

import type { NavigateFunction } from "react-router-dom"

import { config } from "../utils/constants"
import { QuestionBank } from "../types/query"

const app = cloudbase.init({
  env: config.envId,
})

const auth = app.auth({ persistence: "local" })
const db = app.database()

export enum OrderType {
  Assending = 1,
  Desending = -1,
}

interface OrderBy {
  field: string
  orderType: OrderType
}

interface GetQuestionsProp {
  page?: number
  label: number
  subject?: string
  sample?: number
  orderBy?: OrderBy
  properties?: { [key: string]: string | number }
}

const getQuestionById = (questionBank: QuestionBank, id: string) => {
  // console.log(dbType, id);
  return app
    .callFunction({
      name: "get-question-by-id",
      data: { id, questionBank },
    })
}

const getPastpapers = async ({ query }: { query: GetQuestionsProp }) => {
  const { label, subject, page, properties, orderBy } = query
  const q = {
    label,
    ...(page && { page }),
    ...(subject && { subject }),
    ...(orderBy && orderBy),
    ...(properties && Object.entries(properties).length > 0 &&
      Object.fromEntries(
        Object.entries(properties)
          .filter(([, v]) => v)
          .map(([n, v]) => [n, v])
        ,))
  }

  // console.debug(q)

  try {
    const { result } = await app.callFunction({
      name: "get-pastpapers",
      data: q,
    })
    // console.debug(result)

    return {
      ...result,
      data: result.data.map((pastpaper: any) => {
        return {
          ...pastpaper,
          label: parseInt(pastpaper["label"])
        }
      })
    }
  } catch (err) {
    console.error(err)
  }
}


const getQuestions = async ({ questionBank, query }: { questionBank: QuestionBank, query: GetQuestionsProp }) => {
  const { label, subject, page, properties, orderBy } = query
  const q = {
    label,
    questionBank,
    ...(page && { page }),
    ...(subject && { subject }),
    ...(orderBy && orderBy),
    ...(properties && Object.entries(properties).length > 0 &&
      Object.fromEntries(
        Object.entries(properties)
          .filter(([, v]) => v)
          .map(([n, v]) => [n, v])
        ,))
  }

  // console.debug(q)

  try {
    const { result } = await app.callFunction({
      name: "get-questions",
      data: q,
    })
    // console.debug(result)

    return {
      ...result,
      data: result.data.map((question: any) => {
        return {
          ...question,
          label: parseInt(question["label"])
        }
      })
    }
  } catch (err) {
    console.error(err)
  }
}

const getRandomQuestions = ({ questionBank, queries }: { questionBank: QuestionBank, queries: GetQuestionsProp[] }) => {
  if (!(queries && Array.isArray(queries) && queries.length > 0 && Object.keys(queries[0]).length > 0)) {
    return [1]
  }
  const qs = queries.map((query) => {
    const { subject, sample, properties } = query
    let q: { [key: string]: string | number } = {}
    if (subject) {
      q = { subject, sample: sample ? sample : 1 }
    }
    if (properties) {
      Object.entries(properties)
        .filter(([, v]) => v)
        .forEach(([n, v]) => (q[n] = v))
    }
    return q
  })
  // console.log(qs)

  return app.callFunction({
    name: "get-random-questions",
    data: { queries: qs, questionBank }
  }).then(res => {
    // console.log(res)
    return res.result
  })
}

const getTags = ({ questionBank }: { questionBank: QuestionBank }) =>
  app.callFunction({
    name: "get-tags",
    data: { questionBank },
  }).then(res => res.result)
    .catch((error) => {
      console.error("ERROR??? ", error)
    })

const signUp = ({ email, password }: { email: string; password: string }) => {
  return auth.signUpWithEmailAndPassword(email, password)
}

const signIn = ({
  password,
  email,
}: {
  firstName: string
  secondName: string
  password: string
  email: string
  phoneNumber: number
  school: string
  subjects: string[][]
}) => {
  return auth.signInWithEmailAndPassword(email, password)
}

const firstSignIn = () => {
  return app.callFunction({
    name: "firstSignIn",
  })
}

const setQuestionLabel = ({ questionBank, questionId, label }: { questionBank: QuestionBank, questionId: string, label: number }) => {
  return app.callFunction({
    name: "set-question-label",
    data: {
      questionBank,
      questionId,
      label,
    }
  })
}

const setPastpaperLabel = ({ pastpaperId, label }: { pastpaperId: string, label: number }) => {
  return app.callFunction({
    name: "set-pastpaper-label",
    data: {
      pastpaperId,
      label,
    }
  })
}

const setUserInfo = ({
  username,
  phoneNumber,
  school,
  subjects,
}: {
  username: string
  phoneNumber: number
  school: string
  subjects: string[][]
}) => {
  return app.callFunction({
    name: "set-userInfo",
    data: {
      username,
      phoneNumber,
      school,
      subjects,
    },
  })
}

const getUsage = () => {
  return app.callFunction({
    name: "get-usage",
  })
}

const signOut = (callback: () => void) => {
  auth.signOut().then((res) => {
    console.log("signed out")
    callback()
  })
}

const isAuthenciacted = () => auth.hasLoginState()

const forgotPassword = ({ email }: { email: string }) => auth.sendPasswordResetEmail(email)

const firstSignInCheck = async (navigate: NavigateFunction): Promise<{ subjects: Array<{ examId: string, subjectId: string }> }> => {
  if (!isAuthenciacted()) {
    return { subjects: [] }
  }

  return firstSignIn()
    .then((res) => {
      // console.log(res.result);
      if (!res.result.exist) {
        navigate("/me")
        return { subjects: [] }
      } else {
        return res.result.data
      }
    })
    .catch((e) => console.error(e))
}

const generatePaper = () => {
  return app.callFunction({
    name: "generate-paper",
  })
}

const getMessages = async () => {
  return db.collection("messages").orderBy("createTime", "desc").get()
}

const getUserProgress = (lastWeekOnly: boolean) => {
  return app.callFunction({
    name: "get-user-progress",
    data: {
      lastWeekOnly,
    }
  })
}

const getUserProgressBySubject = ({ exam, subject }: { exam: string, subject: string }) => {
  return app.callFunction({
    name: "get-user-progress-by-subject",
    data: {
      exam,
      subject,
    }
  })
}

export {
  getPastpapers,
  setPastpaperLabel,
  getQuestions,
  getQuestionById,
  getRandomQuestions,
  setQuestionLabel,
  getUsage,
  getTags,
  getMessages,
  getUserProgress,
  getUserProgressBySubject,
  generatePaper,
  signUp,
  signIn,
  signOut,
  setUserInfo,
  isAuthenciacted,
  forgotPassword,
  firstSignIn,
  firstSignInCheck,
}
export type { GetQuestionsProp, OrderBy }
