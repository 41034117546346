import React, { FC, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { List, Typography } from "antd";
import { Question } from "../../components/question";

import "./index.scss";

const { Title } = Typography;

const Questions = React.forwardRef<
  HTMLDivElement,
  { questions: Array<Question>; answer: boolean; blankSpace: boolean; title: string }
>(({ questions, answer, blankSpace, title }, ref) => {
  return (
    <div className="pdf" ref={ref}>
      {title.length === 0 ? <></> : <Title level={3}>{title}</Title>}
      <List
        split={false}
        dataSource={questions}
        renderItem={(question, idx) => (
          <List.Item style={{ flexDirection: "column" }}>
            <Question index={idx + 1} data={question} showAnswer={answer} showBlank={blankSpace} />
          </List.Item>
        )}
      />
    </div>
  );
});

declare type useReactToPrint = () => void;

const GeneratePDF: FC<{
  questions: Array<Question>;
  title: string;
  answer: boolean;
  blankSpace: boolean;
  generateRef: React.MutableRefObject<useReactToPrint | null>;
}> = ({ questions, title, answer, blankSpace, generateRef }) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    // console.log("`onBeforePrint` called");
  }, []);

  const handleAfterPrint = React.useCallback(() => {
    // console.log("`onAfterPrint` called");
  }, []);

  const handlePrint = useReactToPrint({
    documentTitle: "myquestionbank_paper",
    content: reactToPrintContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  useEffect(() => {
    generateRef.current = handlePrint;
  }, []);

  return <Questions title={title} answer={answer} blankSpace={blankSpace} questions={questions} ref={componentRef} />;
};

export { GeneratePDF };
export type { useReactToPrint };
