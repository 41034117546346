import { FC } from "react"
import { Image, List, Button, Typography, Row, Col, Space } from "antd"
import { ArrowsAltOutlined } from '@ant-design/icons'
import { MathJax, MathJaxContext } from "better-react-mathjax"
import { decodeSpecialChar } from "../../utils/decode"

import "./index.scss"

const { Text } = Typography

const config = {
  "fast-preview": {
    disabled: false,
  },
  tex2jax: {
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"],
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"],
    ],
  },
  svg: {
    fontCache: "global",
  },
  renderMode: "post",
}

const Question: FC<{
  data: Question
  splitView?: {
    showAnswer: boolean
    showQuestion: boolean
  },
  showAnswer?: boolean
  showBlank?: boolean
  isMobile?: boolean
  brief?: boolean
  index?: number
  fullScreenButton?: boolean
  onClick?: () => void
}> = ({ data, splitView, showAnswer = false, isMobile = false, showBlank = false, brief = false, fullScreenButton = false, index, onClick }) => {
  const { properties = {}, blocks = [] } = data
  const { year = "--", mark = "--", timezone = "--", paper = "--", season = "--" } = properties
  const nonAnswerBlocks = blocks.filter((b) => b.type !== "textAnswer" && b.type !== "imageAnswer")
  return (
    <div
      className={"question"}
      style={{
        height: "100%",
        width: brief ? "30vw" : "",
        maxWidth: brief ? "450px" : "",
        scrollbarWidth: "none"
      }}
    >
      <div
        style={{
          width: "100%",
          height: "20px",
          display: "flex",
          position: "relative",
          top: -5,
          right: -5,
          flexDirection: "row-reverse",
          scrollbarWidth: "none"
        }}
      >
        {
          fullScreenButton &&
          <Button type="text" onClick={onClick} style={{ margin: 0, padding: 0, width: 25, height: 25 }}>
            <ArrowsAltOutlined />
          </Button>
        }
      </div>
      <div style={{ display: "flex", flexDirection: "column", scrollbarWidth: "none" }}>
        <div
          style={{ scrollbarWidth: "none" }}>
          <MathJaxContext version={3} config={config}>
            {
              splitView
                ?
                <>
                  {
                    (splitView.showQuestion && !splitView.showAnswer) &&
                    <div style={{
                      display: "grid",
                      height: "100%",
                    }}>
                      <List
                        style={{ display: "flex", position: "relative", overflowY: "scroll", scrollbarWidth: "none" }}
                        split={false}
                        dataSource={blocks.filter((b) => (b.type !== "imageAnswer") && (b.type !== "textAnswer"))}
                        renderItem={(block, idx) => (
                          <List.Item className="question-item">
                            {
                              index && idx === 0 &&
                              <div className="question-index">
                                <Text strong>{`${index}.  `}</Text>
                                <Text>{`[Maximum mark: ${mark}]`}</Text>
                              </div>
                            }

                            {
                              block.type === "image" ? (
                                <Image preview={false} src={block.data.file.url} />
                              ) : block.type === "paragraph" ? (
                                <MathJax dynamic>
                                  {decodeSpecialChar(block.data.content)}
                                </MathJax>
                              ) : block.type === "blankLine" && showBlank ? (
                                <List
                                  style={{ width: "100%" }}
                                  dataSource={[...Array(block.data.number).keys()]}
                                  renderItem={(i) => (
                                    <hr
                                      style={{
                                        marginTop: "2em",
                                        height: "0px",
                                        border: "none",
                                        borderTop: "2px solid black",
                                      }}
                                      key={i}
                                    />
                                  )}
                                />
                              ) : (
                                <></>
                              )
                            }
                          </List.Item>
                        )}
                      />
                    </div>
                  }
                  {
                    (!splitView.showQuestion && splitView.showAnswer) &&
                    <div style={{
                      display: "grid",
                      height: "100%",
                    }}>
                      <Text strong>Answer:</Text>
                      <List
                        style={{ display: "flex", position: "relative", overflowY: "scroll" }}
                        split={false}
                        dataSource={blocks.filter((b) => (b.type === "imageAnswer") || (b.type === "textAnswer"))}
                        renderItem={(block, idx) => (
                          <List.Item className="question-item">
                            {
                              index && idx === 0 &&
                              <div className="question-index">
                                <Text strong>{`${index}.  `}</Text>
                                <Text>{`[Maximum mark: ${mark}]`}</Text>
                              </div>
                            }

                            {
                              block.type === "imageAnswer" && showAnswer ? (
                                <div className="answer image-answer" style={{ flexDirection: "column" }}>
                                  <Image preview={false} src={block.data.file.url} />
                                </div>
                              ) : block.type === "textAnswer" && showAnswer ? (
                                <div className="answer text-answer" style={{ flexDirection: "column" }}>
                                  <MathJax dynamic>{decodeSpecialChar(block.data.content)}</MathJax>
                                </div>
                              ) : (
                                <></>
                              )
                            }
                          </List.Item>
                        )}
                      />
                    </div>
                  }
                  {
                    (splitView.showQuestion && splitView.showAnswer) &&
                    <div style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gridGap: "20px",
                      height: "calc(85vh - 122px)",
                    }}>
                      <List
                        style={{ display: "flex", position: "relative", overflowY: "scroll" }}
                        split={false}
                        dataSource={blocks.filter((b) => (b.type !== "imageAnswer") && (b.type !== "textAnswer"))}
                        renderItem={(block, idx) => (
                          <List.Item className="question-item">
                            {
                              index && idx === 0 &&
                              <div className="question-index">
                                <Text strong>{`${index}.  `}</Text>
                                <Text>{`[Maximum mark: ${mark}]`}</Text>
                              </div>
                            }

                            {
                              block.type === "image" ? (
                                <Image preview={false} src={block.data.file.url} />
                              ) : block.type === "paragraph" ? (
                                <MathJax dynamic>
                                  {decodeSpecialChar(block.data.content)}
                                </MathJax>
                              ) : block.type === "blankLine" && showBlank ? (
                                <List
                                  style={{ width: "100%" }}
                                  dataSource={[...Array(block.data.number).keys()]}
                                  renderItem={(i) => (
                                    <hr
                                      style={{
                                        marginTop: "2em",
                                        height: "0px",
                                        border: "none",
                                        borderTop: "2px solid black",
                                      }}
                                      key={i}
                                    />
                                  )}
                                />
                              ) : (
                                <></>
                              )
                            }
                          </List.Item>
                        )}
                      />
                      <Space direction="vertical" style={{ display: "flex", position: "relative", overflowY: "scroll" }} >
                        <Text strong>Answer:</Text>
                        <List
                          split={false}
                          dataSource={blocks.filter((b) => (b.type === "imageAnswer") || (b.type === "textAnswer"))}
                          renderItem={(block, idx) => (
                            <List.Item className="question-item">
                              {
                                index && idx === 0 &&
                                <div className="question-index">
                                  <Text strong>{`${index}.  `}</Text>
                                  <Text>{`[Maximum mark: ${mark}]`}</Text>
                                </div>
                              }

                              {
                                block.type === "imageAnswer" && showAnswer ? (
                                  <div className="answer image-answer" style={{ flexDirection: "column" }}>
                                    <Image preview={false} src={block.data.file.url} />
                                  </div>
                                ) : block.type === "textAnswer" && showAnswer ? (
                                  <div className="answer text-answer" style={{ flexDirection: "column" }}>
                                    <MathJax dynamic>{decodeSpecialChar(block.data.content)}</MathJax>
                                  </div>
                                ) : (
                                  <></>
                                )
                              }
                            </List.Item>
                          )}
                        />
                      </Space>
                    </div>
                  }
                </>
                :
                <div style={{ display: "flex", height: "100%", position: "relative", overflowY: isMobile ? "scroll" : "hidden", scrollbarWidth: "none" }}>
                  <MathJaxContext version={3} config={config}>
                    {/* {showAnswer && <Text strong>Answer:</Text>} */}
                    <List
                      split={false}
                      dataSource={brief ? (nonAnswerBlocks.length > 0 ? [nonAnswerBlocks[0]] : [data.blocks[0]]) : data.blocks}
                      renderItem={(block, idx) => (
                        <List.Item className="question-item">
                          {index && idx === 0 ? (
                            <div className="question-index">
                              <Text strong>{`${index}.  `}</Text>
                              <Text>{`[Maximum mark: ${data.properties?.mark}]`}</Text>
                            </div>
                          ) : (
                            <></>
                          )}

                          {
                            block.type === "image" ? (
                              <Image preview={false} src={block.data.file.url} className={brief ? "brief-image" : ""} />
                            ) : block.type === "paragraph" ? (
                              <MathJax dynamic className={brief ? "brief" : ""} style={{ display: brief ? "-webkit-box" : "" }}>
                                {decodeSpecialChar(block.data.content)}
                              </MathJax>
                            ) : block.type === "imageAnswer" && showAnswer ? (
                              <div className="answer image-answer" style={{ flexDirection: "column" }}>
                                <Image preview={false} src={block.data.file.url} className={brief ? "brief-image" : ""} />
                              </div>
                            ) : block.type === "textAnswer" && showAnswer ? (
                              <div className="answer text-answer" style={{ flexDirection: "column" }}>
                                <MathJax dynamic>{decodeSpecialChar(block.data.content)}</MathJax>
                              </div>
                            ) : block.type === "blankLine" && showBlank ? (
                              <List
                                style={{ width: "100%" }}
                                dataSource={[...Array(block.data.number).keys()]}
                                renderItem={(i) => (
                                  <hr
                                    style={{
                                      marginTop: "2em",
                                      height: "0px",
                                      border: "none",
                                      borderTop: "2px solid black",
                                    }}
                                    key={i}
                                  />
                                )}
                              />
                            ) : (
                              <></>
                            )}
                        </List.Item>
                      )}
                    />
                  </MathJaxContext>
                </div>
            }
          </MathJaxContext>
          {
            (!brief) &&
            <Row className="no-print" wrap gutter={[10, 2]}>
              <Col>
                <Row gutter={5}>
                  <Col><Text strong>Year:</Text></Col>
                  <Col><Text>{year}</Text></Col>
                </Row>
              </Col>
              <Col>
                <Row gutter={5}>
                  <Col><Text strong>Mark:</Text></Col>
                  <Col><Text>{mark}</Text></Col>
                </Row>
              </Col>
              <Col>
                <Row gutter={5}>
                  <Col><Text strong>TimeZone:</Text></Col>
                  <Col><Text>{timezone}</Text></Col>
                </Row>
              </Col>
              <Col>
                <Row gutter={5}>
                  <Col><Text strong>Paper:</Text></Col>
                  <Col><Text>{paper}</Text></Col>
                </Row>
              </Col>
              <Col>
                <Row gutter={5}>
                  <Col><Text strong>Season:</Text></Col>
                  <Col><Text>{season}</Text></Col>
                </Row>
              </Col>
              <Col>
                <Row gutter={5}>
                  <Col><Text strong>Chapter:</Text></Col>
                  <Col><Text>{properties.chapter && typeof properties.chapter === "string" ? properties.chapter.split("@")[0] : "--"}</Text></Col>
                </Row>
              </Col>
            </Row>
          }
        </div>
      </div >
    </div >
  )
}

export { Question }
