const decodeSpecialChar = (str: string) => {
  const htmlEntities: { [key: string]: string } = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&apos;": "'",
  };
  return str.replace(/(&amp;)|(&lt;)|(&gt;)|(&quot;)|(&apos;)/g, (match) => htmlEntities[match]);
};

export { decodeSpecialChar };
