import React, { useState, useEffect, FC } from "react"
import { Button, Form, Input, Layout, Space } from "antd"
import { NavLink, useNavigate } from "react-router-dom"
import { MessageInstance } from "antd/es/message/interface"

import { signIn, isAuthenciacted } from "../../utils/apis"

const { Content } = Layout

const SignIn: FC<{ messageApi: MessageInstance }> = ({ messageApi }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  // if (isAuthenciacted()) redirect("/");

  useEffect(() => {
    if (isAuthenciacted()) navigate("/")
  }, [])

  const onFinish = (values: any) => {
    // console.log("Received values of form:", values);

    setLoading(true)

    signIn(values)
      .then((res) => {
        // console.log(res);
        navigate("/")
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        messageApi.error({ content: `Error encountered, please try again. ${err}`, duration: 3 })
        setLoading(false)
      })
  }

  return (
    <Content className="site-layout-background" style={{ paddingBottom: "30px" }}>
      <Space
        direction="horizontal"
        align="start"
        style={{
          columnGap: 15,
          display: "flex",
          margin: "auto",
          justifyContent: "center",
          maxWidth: "90vw",
        }}
      >
        <Space
          direction="vertical"
          style={{
            display: "flex",
            margin: "auto",
            marginTop: 80,
            width: "21cm",
            maxWidth: "max(60vw, 380px)",
          }}
        >
          <Form
            name="login"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 700 }}
            layout="horizontal"
            onFinish={onFinish}
            size={"large"}
          >
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Please input your email!" },
                { type: "email", message: "Please input a valid email!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: "Please input your password!" }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button block type="primary" htmlType="submit" loading={loading}>
                Sign In
              </Button>
              Or <NavLink to="/signUp">sign up now!</NavLink>
              <NavLink style={{ float: "right" }} to="/forgot">
                Forgot password
              </NavLink>
            </Form.Item>
          </Form>
        </Space>
      </Space>
    </Content>
  )
}

export { SignIn }
