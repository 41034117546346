import { Dispatch, FC, SetStateAction, useRef, useState } from "react"
import { Button, Checkbox, Input, List, Space, Typography } from "antd"
import { isChrome } from "react-device-detect"

import { Question } from "../question"
import { GeneratePDF } from "../generate"
import { MoveQuestion } from "../moveQuestion"

import type { useReactToPrint } from "../../components/generate"
import { generatePaper, getQuestionById } from "../../utils/apis"
import { QuestionBank } from "../../types/query"
import { MessageInstance } from "antd/es/message/interface"

const { Text, Title } = Typography

const QuestionList: FC<{
  questionBank: QuestionBank
  loading?: boolean
  questionList: Array<Question>
  messageApi: MessageInstance
  setQuestionList: Dispatch<SetStateAction<Array<Question>>>
}> = ({ questionBank, messageApi, loading = false, questionList, setQuestionList }) => {
  // parameters for PDF generation
  const [generateAnswer, setGenerateAnswer] = useState<boolean>(false)
  const [generateBlankSpace, setGenerateBlankSpace] = useState<boolean>(false)
  const [title, setTitle] = useState<string>("WorkSheet")
  const [verifying, setVerifying] = useState<boolean>(false)
  const [worksheetReady, setWorksheetReady] = useState<boolean>(false)

  // reference to handlePrint function in GeneratePDF component
  const handlePrint = useRef<useReactToPrint | null>(null)

  const clearQuestionList = () => setQuestionList([])

  const removeFromQuestionList = (question: Question | undefined) => {
    if (question && questionList.includes(question)) {
      setQuestionList((prev) => {
        return prev.filter((qid) => qid !== question)
      })
    }
  }

  const hanldeGenerateWorksheet = () => {
    setVerifying(true)
    generatePaper().then(async ({ result }) => {
      // console.log(result)
      const { success, data } = result
      if (success) {
        const questions = await Promise.all(questionList.map((question) => getQuestionById(questionBank, question._id)))
          .then((res) => {
            return res.map(({ result: { success, data } }) => { return { success, data } })
              .filter(({ success }) => success)
              .map(({ data }) => data)
          })
        // console.log(questions)

        setQuestionList(questions)
        setWorksheetReady(true)
      } else {
        messageApi.error({ content: `Error ${data}`, duration: 3 })
      }
      setVerifying(false)
    }).catch((err) => {
      console.log(err)
      setVerifying(false)
    })
  }

  const hanldeGeneratePDF = () => {
    handlePrint.current!()
  }

  return (
    <>
      {!loading && questionList.length > 0 ? (
        <Space direction="vertical" style={{ margin: "auto", marginTop: 80, minHeight: 300 }}>
          <Title level={5}>Selected QuestionList</Title>
          <List
            itemLayout="horizontal"
            size="large"
            split={false}
            dataSource={questionList}
            style={{
              padding: 10,
              borderRadius: 10,
              backgroundColor: "#f0f0f0",
            }}
            renderItem={(question, idx) => (
              <List.Item
                style={{
                  flexDirection: "column",
                  margin: "15px 0px",
                  padding: 0,
                }}
              >
                <Space direction="vertical">
                  <Space>
                    <Text strong>{`Q ${idx + 1}`}</Text>{" "}
                    <MoveQuestion idx={idx + 1} questions={questionList} setQuestions={setQuestionList} />
                    <Button type="link" danger onClick={() => removeFromQuestionList(question)}>
                      delete
                    </Button>
                  </Space>
                  <Question brief data={question} />
                </Space>
              </List.Item>
            )}
          ></List>
          {isChrome ? (
            <Space direction="vertical">
              <Input addonBefore="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
              <Space direction="horizontal">
                <Checkbox onChange={(e) => setGenerateAnswer(e.target.checked)}>Show Answer</Checkbox>
                <Checkbox onChange={(e) => setGenerateBlankSpace(e.target.checked)}>Show Blank Space</Checkbox>
              </Space>
              <Space direction="horizontal">
                {worksheetReady ?
                  (<Button
                    type="primary"
                    onClick={() => hanldeGeneratePDF()}
                  >
                    Generate PDF
                  </Button>
                  ) : (<Button
                    type="primary"
                    loading={verifying}
                    disabled={verifying}
                    onClick={() => hanldeGenerateWorksheet()}
                  >
                    Generate Worksheet
                  </Button>)}
                <GeneratePDF
                  title={title}
                  answer={generateAnswer}
                  blankSpace={generateBlankSpace}
                  questions={questionList}
                  generateRef={handlePrint}
                />
                <Button danger disabled={verifying} onClick={clearQuestionList}>
                  Clear
                </Button>
              </Space>
            </Space>
          ) : (
            <Text strong>{`PDF generation is only available on desktop Chrome browser`}</Text>
          )}
        </Space>
      ) : (
        <></>
      )}
    </>
  )
}

export { QuestionList }
