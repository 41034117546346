import React, { useState, useEffect, FC } from "react"
import { Typography, Button, Form, Input, Layout, Space, Cascader } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { useLoaderData } from "react-router-dom"
import { useNavigate } from "react-router-dom"

import { MessageInstance } from "antd/es/message/interface"

import { setUserInfo, signOut, isAuthenciacted, firstSignIn } from "../../utils/apis"

const { Title, Text } = Typography
const { Content } = Layout

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
}

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    sm: {
      span: 18,
      offset: 6
    },
    xs: {
      span: 24,
      offset: 0,
    }
  },
}

const extractSubjects = (exams: Exam[]) =>
  exams.map(({ _id, name, subjects }) => {
    return {
      value: _id,
      label: name,
      children: subjects.map(({ _id, name }) => {
        return {
          value: _id,
          label: name
        }
      }),
    }
  })

const Me: FC<{ messageApi: MessageInstance }> = ({ messageApi }) => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [firstTime, setFirstTime] = useState(true)
  const [uuid, setUuid] = useState<string>()

  useEffect(() => {
    if (!isAuthenciacted()) navigate("/")
    else {
      messageApi.open({
        type: "loading",
        content: "Action in progress..",
        duration: 3,
      })
      firstSignIn()
        .then((res) => {
          messageApi.destroy()
          if (!res.result.exist) {
            return
          } else {
            const initV = {
              username: res.result.data.username,
              phoneNumber: res.result.data.phoneNumber,
              school: res.result.data.school,
              subjects: res.result.data.subjects.map(({ examId, subjectId }: { examId: string, subjectId: string }) => [examId, subjectId]),
            }
            setUuid(res.result.data._id)
            setFirstTime(false)
            form.setFieldsValue(initV)
          }
        })
        .catch((err) => {
          console.error(err)
          messageApi.error({
            content: "Error encountered, please sign in again.",
            duration: 3,
          })
        })
    }
  }, [messageApi, navigate, form])

  const { exams } = useLoaderData() as { exams: Exams }
  const subjects = extractSubjects(exams)

  const onFinish = (firstTime: boolean) => (values: any) => {
    console.log("Received values of form:", values)

    setLoading(true)
    setUserInfo({
      phoneNumber: values.phoneNumber,
      school: values.school,
      subjects: values.subjects,
      username: values.username,
    })
      .then((res) => {
        // console.log(res)
        messageApi.success({ content: "Settings saved successfully.", duration: 3 })
        if (firstTime) navigate("/")
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        signOut(() => { })
        messageApi.error({ content: "Error encountered, please sign in again.", duration: 3 })
        setLoading(false)
      })
  }

  return (
    <Content className="site-layout-background" style={{ paddingBottom: "30px" }}>
      <Space
        direction="horizontal"
        align="start"
        style={{
          columnGap: 15,
          display: "flex",
          margin: "auto",
          justifyContent: "center",
          maxWidth: "90vw",
        }}
      >
        <Space
          direction="vertical"
          style={{
            display: "flex",
            margin: "auto",
            marginTop: 80,
            padding: "1em",
            width: "80vw",
          }}
        >
          <Form
            name="signup"
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            // style={{ maxWidth: 700 }}
            layout="horizontal"
            onFinish={onFinish(firstTime)}
            size={"large"}
          >
            <Form.Item {...formItemLayoutWithOutLabel}>
              <Title>Account Setttings</Title>
            </Form.Item>

            <Form.Item
              name="username"
              label="Username"
              rules={[
                { required: true, message: "Please input your user name!" },
                { max: 32, message: "The user name length should be no more than 32 characters." },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              rules={[
                { required: true, message: "Please input your phone number!" },
                { max: 20, message: "The phone number length should be no more than 20 characters." },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="school"
              label="School"
              rules={[
                { required: true, message: "Please input your school!" },
                { max: 80, message: "The school name should be no more than 80 characters." },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.List
              name="subjects"
              rules={[
                {
                  validator: async (_, subjects) => {
                    if (!subjects || subjects.length < 1) {
                      return Promise.reject(new Error("At least 1 subject"))
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                      label={index === 0 ? "Subjects" : ""}
                      required={true}
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select a subject or delete this field.",
                          },
                        ]}
                        noStyle
                      >
                        <Cascader
                          changeOnSelect={false}
                          placeholder="subject"
                          options={subjects}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <Button
                          size="middle"
                          type="link"
                          style={{ float: "right" }}
                          onClick={() => remove(field.name)}
                        >
                          Delete
                        </Button>
                      ) : null}
                    </Form.Item>
                  ))}
                  {fields.length < 6 ? (
                    <Form.Item {...formItemLayoutWithOutLabel}>
                      <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                        Add Subject
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  ) : null}
                </>
              )}
            </Form.List>

            {uuid === "" ? null : (
              <Form.Item {...formItemLayoutWithOutLabel}>
                <Text>UUID: {uuid}</Text>
              </Form.Item>
            )}

            <Form.Item {...formItemLayoutWithOutLabel}>
              <Button block type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Space>
    </Content>
  )
}

export { Me }
