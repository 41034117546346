import React, { FC, useState } from "react"
import { Button, Form, Input, Layout, Space } from "antd"
import { useNavigate } from "react-router-dom"
import { MessageInstance } from "antd/es/message/interface"

import { forgotPassword } from "../../utils/apis"

const { Content } = Layout

const ForgotPassword: FC<{ messageApi: MessageInstance }> = ({ messageApi }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  // if (isAuthenciacted()) redirect("/");

  const onFinish = (values: any) => {
    // console.log("Received values of form:", values);
    setLoading(true)

    forgotPassword(values)
      .then((res) => {
        // console.log(res);
        messageApi.success({
          content: "An email with link to reset your password has been sent successfully!",
          duration: 3,
        })
        navigate("/")
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        messageApi.error({ content: `Error encountered, please try again. ${err}`, duration: 3 })
        setLoading(false)
      })
  }

  return (
    <Content className="site-layout-background" style={{ paddingBottom: "30px" }}>
      <Space
        direction="horizontal"
        align="start"
        style={{
          columnGap: 15,
          display: "flex",
          margin: "auto",
          justifyContent: "center",
          maxWidth: "90vw",
        }}
      >
        <Space
          direction="vertical"
          style={{
            display: "flex",
            margin: "auto",
            marginTop: 80,
            width: "21cm",
            maxWidth: "max(60vw, 380px)",
          }}
        >
          <Form
            name="login"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 700 }}
            layout="horizontal"
            onFinish={onFinish}
            size={"large"}
          >
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Please input your email!" },
                { type: "email", message: "Please input a valid email!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button block type="primary" htmlType="submit" loading={loading}>
                Send reset link
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Space>
    </Content>
  )
}

export { ForgotPassword }
