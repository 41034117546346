const prod = {
  API_URL: "https://myquestionbank-9gcfve68fe4574af-1313159134.ap-shanghai.app.tcloudbase.com",
  envId: "myquestionbank-9gcfve68fe4574af",
}

const dev = {
  API_URL: "https://test-3gjwnfyr3d05b061-1313159134.ap-shanghai.app.tcloudbase.com/",
  envId: "test-3gjwnfyr3d05b061",
}

export const config = process.env.NODE_ENV === "development" ? dev : prod

const PageSize = 5
const MaxNumberOfRandomQuery = 3

export {
  PageSize,
  MaxNumberOfRandomQuery
}