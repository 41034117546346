import { FC, useEffect, useState } from "react"
import { Button, Cascader, Col, Form, InputNumber, Row, Select, Space } from "antd"

import type { SingleValueType } from "rc-cascader/lib/Cascader"
import type { GetQuestionsProp } from "../../utils/apis"

import { toUpperCase } from "../../utils/toUpperCase"
import { sort } from "../../utils/sort"

import "./index.scss"
import { QuestionBank, Query } from "../../types/query.d"

interface MyOption {
  value: string
  label: string
  children?: MyOption[]
}

const SubjectSelector: FC<{
  exams: Exam[]
  setSubject: ({ subjectId, examId }: { subjectId: string, examId: string }) => void
}> = ({ exams = [], setSubject }) => {
  const [options, setOptions] = useState<MyOption[]>([])
  const [value, setValue] = useState<string[]>([])

  useEffect(() => {
    const options = exams.map(exam => {
      const { _id, name, subjects } = exam
      return {
        label: name,
        value: _id as string,
        children: subjects
          .sort(({ name: a }, { name: b }) => a.localeCompare(b))
          .map(subject => {
            const { _id, name } = subject
            return {
              label: name,
              value: _id as string,
            }
          })
      }
    })
    setOptions(options)
    setValue([])
  }, [exams])

  const onChange = (value: SingleValueType) => {
    // console.log(value)
    const [examId, subjectId] = value as string[]
    setValue(value as string[])
    setSubject({ examId, subjectId })
  }

  return (
    <Cascader
      placeholder="select a subject first"
      value={value}
      options={options}
      onChange={onChange}
      changeOnSelect={false}
    />
  )
}

const Selector: FC<{
  questionBank: QuestionBank
  loading: boolean
  exams: Exam[]
  setQuery: (q: Query) => void
}> = ({ questionBank, loading, exams = [], setQuery }) => {
  const [examId, setExamId] = useState<string>()
  const [subject, setSubject] = useState<string>()
  const [tags, setTags] = useState<Tag[]>([])
  const [multiLevel, setMultiLevel] = useState<string>()
  const [form] = Form.useForm()
  const onFinish = (values: any) => {
    // console.log("Received values of form: ", values);
    const { subjectId, ...rest } = values
    const entries = Object.entries(rest).filter(([, v]) => v)

    setQuery({
      questionBank,
      examId,
      subjectId,
      properties: entries ? Object.fromEntries(entries as readonly any[]) : {},
    })
  }

  useEffect(() => {
    const { subjects = [] } = exams.find(({ _id }) => _id === examId) || {}
    const { tags = [] } = subjects.find(({ _id }) => _id === subject) || {}
    setTags(tags)
    form.setFieldsValue({ subjectId: subject, examId: examId })
  }, [form, exams, examId, subject])

  useEffect(() => {
    setExamId(undefined)
    setSubject(undefined)
    form.resetFields()
  }, [exams, form])

  const getFields = () => {
    const subjectSelector =
      <Col span={8} key="subjectId">
        <Form.Item
          name={`subjectId`}
          label={`Subject`}
          rules={[
            {
              required: true,
              message: "Select target subject to start",
            },
          ]}
        >
          <SubjectSelector
            exams={exams}
            setSubject={({ subjectId, examId }) => {
              const newFormValues = Object.fromEntries(Object.entries(form.getFieldsValue()).map(([k, v]) => [k, undefined]))
              form.setFieldsValue({ ...newFormValues, subjectId })
              setSubject(subjectId)
              setExamId(examId)
              setMultiLevel(undefined)
            }}
          />
        </Form.Item>
      </Col>

    const tagList = tags
      .sort(({ name: a }, { name: b }) => a.localeCompare(b))
      .map((tag) => {
        const { _id, name, type, defaultValue } = tag
        switch (type) {
          default:
            throw new Error("unknown type")
          case "string":
            const { options = [] } = tag as TagStr
            const sortedOptions = name.toLocaleLowerCase() === "year"
              ? options.sort((a, b) => sort(b.name, a.name)).map(({ name }) => { return { value: name, label: name } })
              : options.sort((a, b) => sort(a.name, b.name)).map(({ name }) => { return { value: name, label: name } })
            return (
              <Col span={8} key={_id}>
                <Form.Item
                  name={`${type}#${name}`}
                  label={toUpperCase(name)}
                  rules={[
                    {
                      required: false,
                      message: "Input something!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    popupMatchSelectWidth={false}
                    placeholder="please select an option"
                    value={defaultValue}
                    onChange={(v) => form.setFieldsValue({ [`string#${name}`]: v })}
                    options={sortedOptions}
                  />
                </Form.Item>
              </Col>
            )
          case "integer": {
            const { min, max } = tag
            return (
              <Col span={8} key={_id}>
                <Form.Item
                  name={`${type}#${name}`}
                  label={toUpperCase(name)}
                  rules={[
                    {
                      required: false,
                      message: "Input something!",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder={`${min} - ${max}`}
                    style={{ width: "100%" }}
                    min={min}
                    max={max}
                    value={defaultValue}
                    onChange={(v) => form.setFieldsValue({ [`integer#${name}`]: v })}
                  />
                </Form.Item>
              </Col>
            )
          }
          case "multi-level": {
            const { options = [] } = tag as TagMul
            // const showMultiLevel = options.filter(({ children }) => children.length > 0)
            const res = [(
              <Col span={8} key={_id}>
                <Form.Item
                  name={`${type}#${name}`}
                  label={toUpperCase(name)}
                  rules={[
                    {
                      required: false,
                      message: "Input something!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    popupMatchSelectWidth={false}
                    placeholder="please select an option"
                    value={options[defaultValue]}
                    onChange={(v) => {
                      // console.log(v)
                      form.setFieldsValue({
                        [`multi-level#${name}`]: v,
                        [`multi-level@#${name}`]: undefined,
                      })
                      setMultiLevel(v)
                    }}
                    options={options.sort((a, b) => sort(a.name, b.name)).map(({ name }) => { return { label: name, value: name } })}
                  />
                </Form.Item>
              </Col>)]

            if (multiLevel !== undefined && multiLevel !== "") {
              const option = options.find(({ name }) => name === multiLevel)
              const { children = [] } = option || {}
              // console.debug(multiLevel, option, children)

              res.push(
                <Col span={8} key={`sub-${_id}`}>
                  <Form.Item
                    name={`${type}@#${name}`}
                    label={`Sub${name}`}
                    rules={[
                      {
                        required: false,
                        message: "Input something!",
                      },
                    ]}
                  >
                    <Select
                      disabled={(multiLevel === undefined) || (multiLevel === "") || (children.length === 0)}
                      allowClear
                      popupMatchSelectWidth={false}
                      placeholder="please select an option"
                      value={defaultValue}
                      onChange={(v) => form.setFieldsValue({ [`multi-level@#${name}`]: v })}
                      options={children.sort(sort).map((v: string) => { return { label: v, value: v } })}
                    />
                  </Form.Item>
                </Col>)
            }
            return res
          }
        }
      })
    return [subjectSelector].concat(tagList.flat())
  }
  return (
    <Form
      form={form}
      style={{ backgroundColor: "#eee" }}
      name="question-search"
      className="question-search"
      disabled={loading}
      onFinish={onFinish}
    >
      <Row gutter={[10, 0]} wrap>{getFields()}</Row>
      <Row justify="end">
        <Space>
          <Button disabled={loading} type="primary" htmlType="submit">
            Search
          </Button>
          <Button disabled={loading} onClick={() => form.resetFields()}>
            Clear
          </Button>
        </Space>
      </Row>
    </Form>
  )
}

const RandomSelector: FC<{
  exams: Exam[]
  setQuery: (q: GetQuestionsProp) => void
  deleteThis: () => void
  query: GetQuestionsProp
}> = ({ exams = [], setQuery, deleteThis, query }) => {
  const [exam, setExam] = useState<string>()
  const [subject, setSubject] = useState<string>()
  const [sample, setSample] = useState<number>(1)
  const [multiLevel, setMultiLevel] = useState<string>()
  const [tags, setTags] = useState<Tag[]>([])
  const [form] = Form.useForm()

  const onFieldsChange = (values: any) => {
    // console.log("Received values of form: ", values);
    const { name, value } = values[0]
    const n = name[0]

    const newQuery = { ...query, examId: exam, subjectId: subject, sample }

    if (value) {
      setQuery({
        ...newQuery,
        properties: { ...newQuery.properties, [n]: value },
      })
    } else {
      let old = { ...newQuery.properties }
      delete old[name]
      setQuery({ ...newQuery, properties: old })
    }
  }

  useEffect(() => {
    const { subjects = [] } = exams.find(({ _id }) => _id === exam) || {}
    const { tags = [] } = subjects.find(({ _id }) => _id === subject) || {}
    setTags(tags)
    setQuery({ ...query, subject })
  }, [exams, exam, subject])

  useEffect(() => {
    setExam(undefined)
    setSubject(undefined)
    setTags([])
    setSample(1)
    form.resetFields()
  }, [exams, form])

  const getFields = () => {
    const defaults = [
      <Col span={8} key="subjectId">
        <Form.Item
          name={`subjectId`}
          label={`Subject`}
          rules={[
            {
              required: true,
              message: "Select target subject to start",
            },
          ]}
        >
          <SubjectSelector
            exams={exams}
            setSubject={({ subjectId, examId }) => {
              const newFormValues = Object.fromEntries(Object.entries(form.getFieldsValue()).map(([k, v]) => [k, undefined]))
              form.setFieldsValue({ ...newFormValues, subjectId })
              setSubject(subjectId)
              setExam(examId)
              setMultiLevel(undefined)
            }}
          />
        </Form.Item>
      </Col>,
      <Col span={8} key="sample">
        <Form.Item name={`sample`} label={`Sample`} rules={[{ required: true }]}>
          <InputNumber
            placeholder={`1 - 5`}
            style={{ width: "100%" }}
            min={1}
            max={5}
            value={1}
            onChange={(v) => setSample(v ? v : sample)}
          />
        </Form.Item>
      </Col>
    ]

    const tagList = tags
      .sort((a, b) => (a.name).localeCompare(b.name))
      .map((tag) => {
        const { _id, name, type, defaultValue } = tag
        switch (type) {
          case "string":
            const { options = [] } = tag as TagStr
            return (
              <Col span={8} key={_id}>
                <Form.Item
                  name={`${type}#${name}`}
                  label={toUpperCase(name)}
                  rules={[
                    {
                      required: false,
                      message: "Input something!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    popupMatchSelectWidth={false}
                    placeholder="please select an option"
                    value={defaultValue}
                    onChange={(v) => form.setFieldsValue({ [`string#${name}`]: v })}
                    options={options.sort((a, b) => sort(a.name, b.name)).map(({ name }) => { return { value: name, label: name } })}
                  />
                </Form.Item>
              </Col>
            )
          case "integer": {
            const { min, max } = tag
            return (
              <Col span={8} key={_id}>
                <Form.Item
                  name={`${type}#${name}`}
                  label={toUpperCase(name)}
                  rules={[
                    {
                      required: false,
                      message: "Input something!",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder={`${min} - ${max}`}
                    style={{ width: "100%" }}
                    min={min}
                    max={max}
                    value={defaultValue}
                    onChange={(v) => form.setFieldsValue({ [`integer#${name}`]: v })}
                  />
                </Form.Item>
              </Col>
            )
          }
          case "multi-level": {
            const { options = [] } = tag as TagMul
            const res = [(
              <Col span={8} key={_id}>
                <Form.Item
                  name={`${type}#${name}`}
                  label={toUpperCase(name)}
                  rules={[
                    {
                      required: false,
                      message: "Input something!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    popupMatchSelectWidth={false}
                    placeholder="please select an option"
                    value={defaultValue}
                    onChange={(v) => {
                      // console.log(v)
                      form.setFieldsValue({
                        [`multi-level#${name}`]: v,
                        [`multi-level@#${name}`]: undefined,
                      })
                      setMultiLevel(undefined)
                    }}
                    options={options.sort((a, b) => sort(a.name, b.name)).map(({ name }) => { return { label: name, value: name } })}
                  />
                </Form.Item>
              </Col>)]

            if (multiLevel) {
              const option = options.find(({ name }) => name === multiLevel)
              const { children: optChildren = [] } = option
              // console.debug(multiLevel, options)

              res.push(
                <Col span={8} key={`sub-${_id}`}>
                  <Form.Item
                    name={`${type}@#${name}`}
                    label={`Sub${name}`}
                    rules={[
                      {
                        required: false,
                        message: "Input something!",
                      },
                    ]}
                  >
                    <Select
                      disabled={multiLevel ? (options.length === 0) : true}
                      allowClear
                      popupMatchSelectWidth={false}
                      placeholder="please select an option"
                      value={defaultValue}
                      onChange={(v) => form.setFieldsValue({ [`multi-level@#${name}`]: v })}
                      options={optChildren.sort(sort).map((v: string) => { return { label: v, value: v } })}
                    />
                  </Form.Item>
                </Col>)
            }
            return res
          }
        }
      })
    return defaults.concat(tagList.flat())
  }
  return (
    <Form
      form={form}
      style={{ backgroundColor: "#eee" }}
      name="question-search"
      className="question-search"
      onFieldsChange={onFieldsChange}
    >
      <Row gutter={24}>{getFields()}</Row>
      <Row>
        <Col span={24} style={{ textAlign: "right" }}>
          <Button style={{ margin: "0 8px" }} onClick={() => deleteThis()}>
            Delete
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export { RandomSelector, Selector }
