import { FC } from "react"
import { Layout, Space, Typography } from "antd"
import { useRouteError } from "react-router-dom"

const { Text } = Typography
const { Content } = Layout

const ErrorPage: FC = () => {
  const error = useRouteError()
  console.error(error)
  return (
    <Content>
      <Space style={{ marginTop: 80, width: "100%", height: "100%", minHeight: "200px", padding: "1em" }}>
        <Text>Error. Invalid path.</Text>
      </Space>
    </Content>
  )
}

export { ErrorPage }
